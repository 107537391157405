import { React, Fragment, useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { useParams } from "react-router-dom";
import { useGetRequest, sendPostRequest, sendGetRequest } from "../Client";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Notyf } from "notyf";
import "notyf/notyf.min.css"; // for React, Vue and Svelte
import { imageURL } from "../config";
import { saveAs } from "file-saver";
import DivisionSidebar from "../Components/DivisionSidebar";
import { MagnifyingGlassIcon, DocumentIcon } from "@heroicons/react/24/outline";
import { TERipple } from "tw-elements-react";

function Division() {
  const { divisionID } = useParams();

  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState({});

  // scroll to top button
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const backToTop = () => {
    document.documentElement.style.scrollBehavior = "smooth";
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // scroll to top button

  const [category, setCategory] = useState(0);
  const [categoryName, setCategoryName] = useState(0);
  const [subCategory, setSubCategory] = useState(0);
  const [subCategoryName, setSubCategoryName] = useState(0);

  const downloadImage = (url) => {
    console.log(url);
    saveAs(url, "image.jpg"); // Put your image URL here.
  };

  const { status, data } = useGetRequest(
    "divisions/products/" +
      process.env.REACT_APP_ACCOUNT_ID +
      "/" +
      divisionID +
      "/" +
      category +
      "/" +
      subCategory,
    {}
  );

  const notyf = new Notyf({
    position: {
      x: "right",
      y: "top",
    },
  });

  const addedNotyf = () => {
    notyf.success("Added to basket");
  };

  const handleChange = (inventoryID, qty, description, image, unitPrice) => {
    setInputs((values) => ({
      ...values,
      [inventoryID]: {
        quantity: qty,
        description: description,
        image: image,
        unitPrice: unitPrice,
      },
    }));
  };

  // adding to basket
  const basketAdd = async (path, body) => {
    const res = await sendPostRequest(path, body);
    addedNotyf();
  };

  const { mutateAsync: doAddMutation } = useMutation({
    mutationFn: (data) => {
      return basketAdd(data.path, { ...data.body });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["basket"] });
    },
  });

  const [filteredDataSource, setFilteredDataSource] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    setFilteredDataSource(data?.products);
  }, [data]);

  const searchFilterFunction = (text) => {
    if (text) {
      const newData = data.products.filter(function (item) {
        const allValues =
          item.productCode +
          item.description +
          item.category1Name +
          item.category2Name;
        const itemData = allValues ? allValues : "";
        const textData = text;
        return itemData.indexOf(textData) > -1;
      });
      setFilteredDataSource(newData);
      setSearch(text);
    } else {
      setFilteredDataSource(data?.products);
      setSearch(text);
    }
  };

  if (status === "fetched") {
    return (
      <>
        <Navbar />

        <div className="bg-white">
          <div className="mx-auto max-w-2xl px-4 pb-4 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {data.division == "All" ? "" : data.division}
            </h1>

            <div className="flex flex-row">
              <div className="w-[20%]">
                <DivisionSidebar
                  divisionID={divisionID}
                  setCategory={setCategory}
                  setSubCategory={setSubCategory}
                  setCategoryName={setCategoryName}
                  setSubCategoryName={setSubCategoryName}
                />
              </div>
              <div className="w-[80%]">
                <div className="ml-0 flow-root overflow-hidden">
                  <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    {categoryName || subCategoryName ? (
                      <div className="font-medium rounded-xl p-2 bg-blue-100 ml-[30%] w-32 text-center">
                        {categoryName
                          ? categoryName
                          : subCategoryName
                          ? subCategoryName
                          : ""}
                      </div>
                    ) : null}

                    <input
                      className="ml-[75%] mt-2 px-3 py-3.5 text-left text-sm font-medium text-grey-500"
                      type="text"
                      style={{
                        borderRadius: 20,
                        borderColor: "gray",
                        width: 200,
                      }}
                      onChange={(text) =>
                        searchFilterFunction(text.target.value)
                      }
                      placeholder="Search"
                      value={search}
                    ></input>
                    <table className="w-full text-left mb-80">
                      <thead className="bg-white">
                        <tr className="w-full">
                          <th
                            scope="col"
                            className="w-[10%] relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Product Code
                          </th>

                          <th
                            scope="col"
                            className="w-[10%] relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                          >
                            Categories
                          </th>

                          <th
                            scope="col"
                            className="w-[10%] hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                          >
                            Description
                          </th>

                          {process.env.REACT_APP_ACCOUNT_ID === "2" ? (
                            <>
                              <th
                                scope="col"
                                className="w-[10%] hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                              >
                                Type Piece
                              </th>
                              <th
                                scope="col"
                                className="w-[10%] hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                              >
                                Unit Price
                              </th>
                              <th
                                scope="col"
                                className="w-[10%] hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                              >
                                Handling Fee
                              </th>
                            </>
                          ) : null}

                          <th
                            scope="col"
                            className="w-[10%] hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                          >
                            Min / Max
                          </th>
                          <th
                            scope="col"
                            className="w-[10%] hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                          >
                            Stock
                          </th>

                          <th
                            scope="col"
                            className="w-[10%] hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                          ></th>
                          <th
                            scope="col"
                            className="w-[10%] hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell"
                          ></th>
                        </tr>
                      </thead>
                      <tbody className="w-full">
                        {data?.products?.length === 0 ? (
                          <>
                            <tr className="w-full">
                              <td className="p-4 text-sm text-red-600 text-left">
                                No Results
                              </td>
                            </tr>
                          </>
                        ) : null}

                        {filteredDataSource?.map((product, key) =>
                          // {data?.products?.map((product, key) => (
                          product.category1 ? (
                            <tr key={product.productCode}>
                              <td
                                onClick={() =>
                                  product.image
                                    ? downloadImage(imageURL + product.image)
                                    : alert("No image available")
                                }
                                className="w-[10%] relative py-2 pr-3 text-sm font-medium text-gray-900 underline cursor-pointer"
                              >
                                <div className="flex flex-row">
                                  <div>{product.productCode}</div>

                                  <div
                                    className="ml-2"
                                    onClick={() =>
                                      downloadImage(imageURL + product.image2)
                                    }
                                  >
                                    {product.image2 ? (
                                      <img
                                        className="mx-auto h-4 w-auto"
                                        src={"/assets/adobepdf.png"}
                                        alt={"/assets/adobepdf.png"}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </td>

                              <td className="w-[10%] hidden py-4 text-xs text-gray-500 sm:table-cell">
                                {product.category1Name} |{" "}
                                {product.category2Name}
                              </td>

                              <td className="w-[10%] hidden px-3 py-4 text-xs text-gray-500 sm:table-cell">
                                {product.description}
                              </td>

                              {process.env.REACT_APP_ACCOUNT_ID === "2" ? (
                                <>
                                  <td className="w-[10%] hidden px-3 py-4 text-xs text-gray-500 sm:table-cell">
                                    {product.typePiece !== "null"
                                      ? product.typePiece
                                      : ""}
                                  </td>

                                  <td className="w-[10%] hidden px-3 py-4 text-xs text-gray-500 sm:table-cell">
                                    {product.unitPrice}
                                  </td>

                                  <td className="w-[10%] hidden px-3 py-4 text-xs text-gray-500 sm:table-cell">
                                    {product.price2}
                                  </td>
                                </>
                              ) : null}

                              <td className="w-[10%] hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                {product.min ? product.min : 0} /{" "}
                                {product.max ? product.max : 0}
                              </td>
                              <td className="w-[10%] hidden px-3 py-4 text-sm text-gray-500 md:table-cell">
                                {product.stockIn -
                                  product.stockOut +
                                  product.importStockLevel}
                              </td>

                              <td className="w-[10%] text-sm text-gray-500">
                                <input
                                  type="text"
                                  name="qty"
                                  onChange={(event) => {
                                    handleChange(
                                      product.inventoryID,
                                      event.target.value,
                                      product.description,
                                      product.image,
                                      product.unitPrice
                                    );
                                  }}
                                  id="qty"
                                  className="border block w-full rounded-md py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </td>
                              <td className="w-[10%] text-sm text-gray-500 pb-2 pl-5">
                                <button
                                  type="button"
                                  inputmode="numeric"
                                  className="mt-2 items-start rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-500"
                                  onClick={async () => {
                                    try {
                                      console.log("inputs", inputs);

                                      await doAddMutation({
                                        path: "basket/add",
                                        body: {
                                          // inventoryID: product.inventoryID,
                                          // description: product.description,
                                          // image: product.image,
                                          // unitPrice: product.unitPrice,
                                          // quantity: inputs.qty,
                                          inputs: inputs,
                                        },
                                      });
                                      window.location.href =
                                        "/division/" + divisionID;
                                    } catch (e) {
                                      console.log(e);
                                      setInputs({});
                                    }
                                  }}
                                >
                                  Add
                                </button>
                              </td>
                            </tr>
                          ) : null
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <>
          {showButton && (
            <TERipple rippleColor="light">
              <button
                type="button"
                onClick={backToTop}
                className={` ${
                  showButton ? `inline-block` : `hidden`
                } fixed bottom-[40px] right-[40px] p-3 bg-indigo-600 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-indigo-700 hover:shadow-lg focus:bg-indigo-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-indigo-800 active:shadow-lg transition duration-150 ease-in-out`}
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  className="w-4 h-4"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z"
                  ></path>
                </svg>
              </button>
            </TERipple>
          )}
        </>

        <Footer />
      </>
    );
  }
}

export default Division;

import { React, Fragment, useState, useEffect } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { sendPostRequest, useGetRequest, sendGetRequest } from "../Client";
import { dateFormatter } from "../config";
import ReactLoading from "react-loading";

function MyOrders() {
  const [searchOrderNumber, setSearchOrderNumber] = useState("");
  const [searchName, setSearchName] = useState("");
  const [searchFromDate, setSearchFromDate] = useState("");
  const [searchToDate, setSearchToDate] = useState("");
  const [searchStatus, setSearchStatus] = useState("");

  const [searched, setSearched] = useState(false);
  const [loading, setLoading] = useState(false);

  const [fetchedData, setFetchedData] = useState([]);
  const [fetched, setFetched] = useState(false);

  const { status: statusDropdownStatuses, data: dataDropdownStatuses } =
    useGetRequest("statuses/list", {});

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // loading orders
  const { data: orders, isLoading } = useQuery({
    queryFn: () =>
      sendPostRequest(
        "customer/orders/" + process.env.REACT_APP_ACCOUNT_ID,
        {}
      ),
    queryKey: ["orders"],
  });


  useEffect(() => {
    setFetchedData(orders);
    setFetched(true);
  }, [orders]);

  const fetchPosts = async () => {
    setLoading(true);

    const requestBody = {
      orderID: searchOrderNumber,
      orderName: searchName,
      fromDate: searchFromDate,
      toDate: searchToDate,
      status: searchStatus,
    };

    const res = await sendPostRequest(
      "customer/orders/" + process.env.REACT_APP_ACCOUNT_ID,
      requestBody
    );

    console.log("res", res);

    setFetchedData(res);
    setLoading(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearched(true);
    fetchPosts();
  };

  if (orders && !fetchedData) {
    <div className="inline-block min-w-full py-2 flex justify-center sm:px-6 lg:px-8">
      <ReactLoading type={"spin"} color="#5046e5" />
    </div>;
  }

  if (fetchedData && statusDropdownStatuses == "fetched") {
    return (
      <>
        <Navbar></Navbar>
        <div className="bg-white">
          <div className="mx-auto max-w-2xl px-4 pb-4 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              My Orders
            </h1>

            <div className="grid grid-cols-3 gap-20 mt-5">
              <div className="col-span-2 p-2">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Order Number
                      </th>
                      <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                      >
                        Ship Name
                      </th>
                      <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                      >
                        Order Date
                      </th>
                      <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                      >
                        Ship Date
                      </th>

                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Ship Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Tracking
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {fetchedData?.orders?.length === 0 ? (
                      <tr>
                        <td colSpan={4}>
                          <div className="ml-60 mt-4 text-gray-400 text-sm inline-block min-w-full py-2 flex justify-left sm:px-6 lg:px-8">
                            -- No orders found --
                          </div>
                        </td>
                      </tr>
                    ) : (
                      fetchedData?.orders?.map((order, id) => (
                        <>
                          <tr key={order.id + order.orderDate + id} 
                          
                          className={classNames(
                          order.statusName === "Pending"
                          ? "bg-indigo-50" : "bg-white",
                          )}
                          >
                            <td
                              className={classNames(
                                id === 0 ? "" : "border-t border-transparent",
                              
                                "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                              )}
                            >
                              <div className="font-medium text-gray-900 underline hover:text-indigo-600 ">
                                <Link to={`/order/details/${order.orderID}`}>
                                  {order.orderID}
                                </Link>
                              </div>
                              <div className="mt-1 flex flex-col text-gray-500 sm:block lg:hidden">
                                <span></span>
                                <span className="hidden sm:inline"></span>
                                {/* <span>{order.orderNumber}</span> */}
                              </div>
                              {id !== 0 ? (
                                <div className="absolute -top-px left-6 right-0 h-px bg-gray-200" />
                              ) : null}
                            </td>
                            <td
                              className={classNames(
                                id === 0 ? "" : "border-t border-gray-200",
                                "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                              )}
                            >
                              {order.orderName}
                            </td>
                            <td
                              className={classNames(
                                id === 0 ? "" : "border-t border-gray-200",
                                "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                              )}
                            >
                              {dateFormatter(order.orderDate)}
                            </td>
                            <td
                              className={classNames(
                                id === 0 ? "" : "border-t border-gray-200",
                                "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                              )}
                            >
                              {order.shipDate
                                ? dateFormatter(order.shipDate)
                                : ""}
                            </td>

                            <td
                              className={classNames(
                                id === 0 ? "" : "border-t border-gray-200",
                                "px-3 py-3.5 text-sm text-gray-500"
                              )}
                            >
                              <div className="text-gray-500 underline hover:text-indigo-600">
                                {order.statusName}
                              </div>
                            </td>

                            <td
                              className={classNames(
                                id === 0 ? "" : "border-t border-gray-200",
                                "px-3 py-3.5 text-sm text-gray-500"
                              )}
                            >
                              <div className="text-gray-500 hover:underline">
                                {order.carrierID === 2 ? (
                                  <div
                                    onClick={() =>
                                      window.open(
                                        `https://www.fedex.com/fedextrack/?trknbr=${order.tracking}&trkqual=2460167000~782296566318~FX`
                                      )
                                    }
                                    className="hover:underline cursor-pointer"
                                  >
                                    {order.tracking}
                                  </div>
                                ) : // <Link
                                //   to={`https://www.fedex.com/fedextrack/?trknbr=${order.tracking}&trkqual=2460167000~782296566318~FX`}
                                // >
                                //   {order.tracking}
                                // </Link>
                                null}
                              </div>
                            </td>
                          </tr>
                        </>
                      ))
                    )}
                  </tbody>
                </table>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="col-span-1">
                  <div className="mb-5 mt-4">
                    <label
                      htmlFor="searchStatus"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Order Status
                    </label>
                    <div className="mt-2">
                      <select
                        id="searchStatus"
                        name="searchStatus"
                        onChange={(e) => {
                          setSearchStatus(e.target.value);
                        }}
                        className="h-[60%] w-full block rounded-md border border-transparent py-1.5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      >
                        <option value=""></option>
                        {dataDropdownStatuses?.statusList.map((status) => (
                          <option value={status.orderStatusID}>
                            {status.statusName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="mb-5 mt-4">
                    <label
                      htmlFor="orderNumber"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Order Number
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="orderNumber"
                        id="orderNumber"
                        onChange={(e) => setSearchOrderNumber(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="mb-5">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Ship To Name
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        onChange={(e) => setSearchName(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="mb-5">
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="fromDate"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        From Date
                      </label>
                      <div className="mt-2">
                        <input
                          type="date"
                          name="fromDate"
                          id="fromDate"
                          onChange={(e) => setSearchFromDate(e.target.value)}
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="toDate"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      To Date
                    </label>
                    <div className="mt-2">
                      <input
                        type="date"
                        name="toDate"
                        id="toDate"
                        onChange={(e) => setSearchToDate(e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-end gap-x-6 mt-5">
                    <button
                      type="submit"
                      className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="mt-10"></div>

        <Footer />
      </>
    );
  }
}

export default MyOrders;
